import React from "react"
import { MarketingDashSVG } from "../MarketingDashSVG"
import { Body, H2 } from "../Typography"
import { IconWrapper, TextBlockContainer, TextBlockWrapper } from "./MarketingTextBlock.styles"

type MarketingTextBlockProps = {
    title?: JSX.Element,
    description: JSX.Element,
    backgroundColor?: string,
    icon?: JSX.Element,
    useDash?: boolean,
    noPadding?: boolean,
    descriptionMaxWidth?: number,
    textAlignment?: string,
    blockMaxWidth?: number
}

export const MarketingTextBlock = ({ title, icon, description, backgroundColor, useDash, noPadding = false, descriptionMaxWidth, textAlignment, blockMaxWidth }: MarketingTextBlockProps) => {
    const hasIcon = typeof icon !== "undefined";

    return <TextBlockWrapper
        $noPadding={noPadding}
        $backgroundColor={backgroundColor}
    >
        {
            useDash && <MarketingDashSVG />
        }
        {hasIcon && <IconWrapper>
            {icon}
        </IconWrapper>}
        <TextBlockContainer maxWidth={descriptionMaxWidth} textAlignment={textAlignment} blockMaxWidth={blockMaxWidth}>
            {!!title && <H2>{title}</H2>}
            <Body>{description}</Body>
        </TextBlockContainer>
    </TextBlockWrapper>
}