import { LocalStorageKeys } from "../../localstorage/LocalStorage";
import { AuthenticatedUser } from '../../types/user';
import { BaseTracker } from '../BaseTracker';

// This class is a utility helper that should be used to call MixPanel tracking methods
export class FacebookPixelTrackerMock extends BaseTracker {

    constructor(user: AuthenticatedUser) {
        super(user, process.env.NEXT_PUBLIC_FB_PIXEL_ID, true, LocalStorageKeys.tracking_dedup_facebook);
    }

    protected initializeInternal() {
        console.log("Mock - Facebook Pixel initializeInternal");
    }

    protected trackPageViewInternal() {
        console.log("Mock - Facebook Pixel trackPageViewInternal");
    }

    protected trackEventInternal(key: string) {
        console.log("Mock - Facebook Pixel trackEventInternal", key);
    }

    protected shutdownInternal() {
        console.log("Mock - Facebook Pixel shutdownInternal");
    }
}