import styled from "styled-components";
import { media } from "../../theme/theme";
import { MarketingSpacing } from "../ReusableStyledComponents/MarketingSpacing";
import { Body, H2 } from "../Typography";

export const TextBlockWrapper = styled.div<{ $backgroundColor?: string, $noPadding: boolean }>`
    width: 100%;
    background:  ${({ $backgroundColor, theme }) => $backgroundColor ? $backgroundColor : theme.marketing.colors.blackPearl30};
    padding: ${({ $noPadding }) => !$noPadding ? "45px 0" : null};

    ${media.tablet} {
        padding:  ${({ $noPadding }) => !$noPadding ? "60px 0" : null};
    }
`;

export const TextBlockContainer = styled.div <{ maxWidth?: number, textAlignment?: string, blockMaxWidth?: number }>`
    width: 100%;
    max-width: ${({ blockMaxWidth }) => blockMaxWidth ? `${blockMaxWidth}px` : "790px"};
    margin: 0 auto;
    ${MarketingSpacing}
    
    ${H2} {
        text-align: ${({ textAlignment }) => textAlignment ? textAlignment : "center"};
        margin-bottom: 25px;
    }

    ${Body} {
        text-align: ${({ textAlignment }) => textAlignment ? textAlignment : "center"};
        color:  ${({ theme }) => theme ? theme.marketing.colors.blackPearl80 : null};
        max-width:  ${({ maxWidth }) => maxWidth ? `${maxWidth}px` : null};
        margin: 0 auto;

        > p {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
`;

export const IconWrapper = styled.div`
    width: 95px;
    margin: 0 auto 30px;
`;