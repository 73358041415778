import { PAGE_VIEW_EVENT } from "../utils/dedupedTrack";
import { LocalStorageKeys } from "../../localstorage/LocalStorage";
import { BaseTracker } from "../BaseTracker";
import { AuthenticatedUser } from "../../types/user";

/**
 * TwitterPixelTracker class responsible for tracking twitter events
 */
export class TwitterPixelTracker extends BaseTracker {

    constructor(user: AuthenticatedUser) {
        super(user, process.env.NEXT_PUBLIC_TWITTER_PIXEL_ID, true, LocalStorageKeys.tracking_dedup_twitter);
    }

    protected initializeInternal() {
        const script = document.createElement('script');

        script.innerHTML = `
            !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            // Insert Twitter Pixel ID and Standard Event data below
            twq('init','${this.providerToken!}');
        `
        document.head.insertBefore(script, document.head.childNodes[0]);
    }

    protected trackPageViewInternal() {
        //@ts-ignore
        window.twq("track", PAGE_VIEW_EVENT);
    }

    protected trackEventInternal(key: string, data: any) {
        //@ts-ignore
        window.twq("track", key, data);
    }

    protected shutdownInternal() {
    }
}
