import { useEffect } from "react"
import { theme } from "../../theme/theme"
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35"
import { VerticalSpacing } from "../VerticalSpacing"
import { MarketingG2BannerWrapper, MarketingG2SVG, StarBannerWrapper } from "./MarketingG2Banner.styles"

const StarSvg = () => <svg xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill={theme.v3.colors.yellow}
>
    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
</svg>

interface MarketingG2BannerProps {
    useScript?: boolean
}

export const MarketingG2Banner = ({ useScript = false }: MarketingG2BannerProps) => {
    useEffect(() => {
        if (!useScript || process.env.NODE_ENV === 'development') return;

        const inlineScript = document.createElement('script');
        inlineScript.innerHTML = '(function(a,b,c,d){window.fetch("https://www.g2.com/products/authory/rating_schema.json").then(e=>e.json()).then(f=>{c=a.createElement(b);c.type="application/ld+json";c.text=JSON.stringify(f);d=a.getElementsByTagName(b)[0];d.parentNode.insertBefore(c,d);});})(document,"script");';
        document.body.append(inlineScript);

        return () => {
            inlineScript.remove();
        };
    }, []);

    return <>
        <MarketingG2BannerWrapper>
            <div><MarketingG2SVG src="/authory_static_files/images/g2/high_performer_spring_2023.svg" /></div>
            <div>
                <div>
                    <VerticalSpacing bottom={8}>
                        <V3CaptionGrey35>Rated 4,8/5 stars</V3CaptionGrey35>
                    </VerticalSpacing>
                    <StarBannerWrapper>
                        {
                            new Array(5).fill(0).map((_elem, index) => <div key={index}><StarSvg /></div>)
                        }
                    </StarBannerWrapper>
                </div>
            </div>
            <div><MarketingG2SVG src="/authory_static_files/images/g2/momentum_leader_spring_2023.svg" /></div>
        </MarketingG2BannerWrapper>
    </>;
}


