import TagManager from 'react-gtm-module';
import { AuthenticatedUser } from '../../types/user';
import { BaseTracker } from '../BaseTracker';

/**
 * GoogleTagManagerTracker class responsible for google tag manager events
 */
export class GoogleTagManagerTracker extends BaseTracker {

    constructor(user: AuthenticatedUser) {
        super(user, process.env.NEXT_PUBLIC_GTM_ID);
    }

    protected initializeInternal() {
        TagManager.initialize({
            gtmId: this.providerToken!
        });
    }

    protected trackPageViewInternal() { }

    protected trackEventInternal(_: string, data: any) {
        TagManager.dataLayer({ dataLayer: data });
    }

    protected shutdownInternal() {
    }
}