import styled from "styled-components";
import { media } from "../../theme/theme";

export const MarketingG2BannerWrapper = styled.div`
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 270px;

    ${media.phone} {
        flex-wrap: nowrap;
        max-width: 520px;
    }

    > div {
        display: inline-flex;
        text-align: center;

        &:nth-child(2) {
            margin: 0 auto 12px;
            width: 100%;
            order: -1;
            display: flex;
            justify-content: center;

            ${media.phone} {
                display: initial;
                order: initial;
                width: auto;
                margin: 0 40px;
            }
        }
    }
`;

export const MarketingG2SVG = styled.img`
    max-width: 100px;
    height: auto;
    width: 100%;
    margin: 0 10px;
`;

export const StarBannerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
`;