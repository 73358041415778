import { AuthenticatedUser } from '../../types/user';
import { BaseTracker } from '../BaseTracker';

// This class is a utility helper that should be used to call MixPanel tracking methods
export class GoogleTagManagerTrackerMock extends BaseTracker {

    constructor(user: AuthenticatedUser) {
        super(user, process.env.NEXT_PUBLIC_GTM_ID);
    }

    protected initializeInternal() {
        console.log("Mock - Google Tag Manager initializeInternal");
    }

    protected trackPageViewInternal() {
        console.log("Mock - Google Tag Manager trackPageViewInternal");
    }

    protected trackEventInternal(_: string, data: any) {
        console.log("Mock - Google Tag Manager trackEventInternal", data);
    }

    protected shutdownInternal() {
        console.log("Mock - Google Tag Manager shutdownInternal");
    }
}