import { LocalStorageKeys } from "../../localstorage/LocalStorage";
import { AuthenticatedUser } from "../../types/user";
import { BaseTracker } from "../BaseTracker";

// This class is a utility helper that should be used to call Twitter Pixel tracking methods
export class TwitterPixelTrackerMock extends BaseTracker {

    constructor(user: AuthenticatedUser) {
        super(user, process.env.NEXT_PUBLIC_TWITTER_PIXEL_ID, true, LocalStorageKeys.tracking_dedup_twitter);
    }

    protected initializeInternal() {
        console.log("Mock - TwitterPixelTracker initializeInternal");
    }

    protected trackPageViewInternal() {
        console.log("Mock - TwitterPixelTracker trackPageViewInternal");
    }

    protected trackEventInternal(key: string) {
        console.log("Mock - TwitterPixelTracker trackEventInternal", key);
    }

    protected shutdownInternal() {
        console.log("Mock - TwitterPixelTracker shutdownInternal");
    }
}
