import { PAGE_VIEW_EVENT } from "../utils/dedupedTrack";
import { LocalStorageKeys } from "../../localstorage/LocalStorage";
import { BaseTracker } from '../BaseTracker';
import { AuthenticatedUser } from "../../types/user";

/**
 * FacebookPixelTracker class responsible for facebook pixel events
 */
export class FacebookPixelTracker extends BaseTracker {

    constructor(user: AuthenticatedUser) {
        super(user, process.env.NEXT_PUBLIC_FB_PIXEL_ID, true, LocalStorageKeys.tracking_dedup_facebook);
    }

    protected initializeInternal() {
        // This package is browser only - loaded here as a result
        const ReactPixel = require('react-facebook-pixel');
        ReactPixel.default.init(this.providerToken!);
    }

    protected trackPageViewInternal() {
        const ReactPixel = require('react-facebook-pixel');
        ReactPixel.default.track(PAGE_VIEW_EVENT);
    }

    protected trackEventInternal(key: string) {
        const ReactPixel = require('react-facebook-pixel');
        ReactPixel.default.track(key);
    }

    protected shutdownInternal() {
    }
}