import ReactModal from "react-modal";
import styled from "styled-components";
import { MARKETING_TITLE_BR } from "../../components/ReusableStyledComponents/MarketingMainTitle";
import { media } from "../../theme/theme";

export const HomepageTitleCarouselWrapper = styled.div`
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .swiper-wrapper {
        height: 48px;

        ${media.custom(MARKETING_TITLE_BR)} {
            height: 65px;
        }
    }

    .swiper-slide {
        height: auto;
    }
`;

export const HPMainFloatWrapper = styled.div<{ userVariant?: boolean }>`
    ${media.tablet} {
        position: relative;
        top: ${({ userVariant }) => userVariant ? "130px" : "100px"};
    }
`;

export const HPMainBtnWrapper = styled.div<{ userVariant?: boolean }>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    ${media.tablet} {
        flex-direction: row;
    }

    > div {
        &:nth-child(2) {
            margin-top: 18px;

            ${media.tablet} {
                margin-top: 0;
                margin-left: 32px;
            }
        }
    }

    button {
        ${media.tablet} {
            font-size: 24px;
        }
    }
`;

export const HPVideoIframe = styled.div`
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
    position: relative;
    z-index: 1;
`;

export const YTHPIframe = styled.iframe`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

export const YoutubeSizer = styled.div<{ noPadding?: boolean }>`
    max-width: 868px;
    margin: 0 auto;
    width: 100%;
    padding: ${({ noPadding }) => noPadding ? "0;" : "0 15px;"}; 
`;

export const MarketingHiddenMobileblock = styled.div`
    display: none;

    ${media.tablet} {
        display: block;
    }
`;

export const MarketingHomepageImageVariant = styled.img`
    width: 100%;
    max-width: 864px;
    margin: 0 auto;
    display: block;
    position: relative;
    z-index: 1;
`;

export const HMModal = styled(ReactModal)`
    width: 800px;
    max-width: calc(100vw - 42px);
    border-radius: 12px;
    transform: translate(-50%, -50%);
    inset: 50% auto auto 50%;
    bottom: initial;
    position: absolute;
    max-height: 95vh;
    top: 50%;
    left: 50%;
`;

export const HMModalIconClosePos = styled.div`
    position: absolute;
    right: -3px;
    top: -32px;
    cursor: pointer;

    &:hover {
        path {
            stroke: white;
        }
    }
`;